import axios from '@/libs/axios';
import { OnlineExhibition } from './variable.js';

//参展商列表
export const list = (data) => {
    return axios.request({
        url: OnlineExhibition.list,
        data: data,
        method: 'post'
    });
};

//展馆类型
export const pavilion = (data) => {
  return axios.request({
      url: OnlineExhibition.pavilion,
      data: data,
      method: 'get'
  });
};