<template>
    <Main :qrcode="true">
        <Header checked="online-exhibition"></Header>
        <Banner></Banner>
        <div class="background">
            <Block>
                <div class="online-exhibition">
                    <div class="title">
                        <div class="left">
                            <!-- <div class="line"></div>
                            <div class="left-title">
                                <div class="zh-title">线上观展</div>
                                <div class="en-title">/Exhibition List</div>
                            </div> -->
                            <el-scrollbar :native="false" :viewClass="{'tabs-container': true}">
                              <div :class="{'tab-item': true, 'tab-item-active': type == item.id}" v-for="(item, i) in typeList" :key="i" @click="changeType(item.id)">{{ item.name }}</div>                              
                            </el-scrollbar>
                        </div>
                        <div class="right">
                            <div class="search">
                                <el-select v-model="searchChecked" class="select" size="mini">
                                    <el-option v-for="item in searchSelect" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                                <div class="line"></div>
                                <el-input class="input" :placeholder="$t('onlineExhibition.searchKeyword')" v-model="keyword" @keyup.enter.native="search" clearable></el-input>
                            </div>
                            <div class="search-button" @click="search">{{$t('onlineExhibition.searchButton')}}</div>

                        </div>
                    </div>
                    <div class="list" v-loading="loading">
                        <template v-for="(item, i) in list">
                          <!-- 有logo有产品 -->
                          <div class="item" v-if="item.logo && item.count > 0">
                            <div class="top">
                              <el-image class="logo pointer" fit="contain" :src="item.logo" @click.native="jump('/exhibitor', {id: item.id})" :title="getLanguageData({zh: item.company_name, en: item.company_en_name})">
                              </el-image>
                              <div class="company-info">
                                <div class="name" @click="jump('/exhibitor', {id: item.id})" :title="getLanguageData({zh: item.company_name, en: item.company_en_name})">{{ getLanguageData({zh: item.company_name, en: item.company_en_name}) }}</div>
                                <div class="line2" v-if="item.booth">
                                  <div class="text1">{{$t('onlineExhibition.booth')}}：</div>
                                  <div class="text2 booth" :title="item.booth">{{item.booth}}</div>
                                </div>
                              </div>
                              <div class="top-contact">
                                <div class="line2" v-if="getLanguageData({zh: item.contact, en: item.contact_en})">
                                  <div class="text1">{{$t('onlineExhibition.contact')}}：</div>
                                  <div class="text2 ellipsis" :title="getLanguageData({zh: item.contact, en: item.contact_en})">{{ getLanguageData({zh: item.contact, en: item.contact_en}) }}</div>
                                </div>
                                <div class="line2" v-if="item.mobile">
                                  <div class="text1">{{$t('onlineExhibition.mobile')}}：</div>
                                  <div class="text2">{{item.mobile}}</div>
                                </div>
                              </div>
                            </div>
                            <div class="bottom">
                              <div class="product-item" v-for="(toyItem, j) in item.toy" :key="j">
                                <el-image class="img pointer" fit="contain" :src="toyItem.main_picture" @click.native="jump('/goodsinfo', {id: toyItem.toy_id})" :title="getLanguageData({zh: toyItem.toy_name, en: toyItem.toy_name_en}) + '（' + toyItem.article_number + '）'">
                                </el-image>
                                <div class="name pointer" @click="jump('/goodsinfo', {id: toyItem.toy_id})" :title="getLanguageData({zh: toyItem.toy_name, en: toyItem.toy_name_en})">{{ getLanguageData({zh: toyItem.toy_name, en: toyItem.toy_name_en}) }}</div>
                                <div class="number" :title="toyItem.article_number">{{toyItem.article_number}}</div>
                              </div>
                              <div class="product-item-placeholder"></div>
                              <div class="product-item-placeholder"></div>
                              <div class="product-item-placeholder"></div>
                            </div>
                          </div>
                          <!-- 有logo无产品 -->
                          <div class="item item2" v-else-if="item.logo && item.count == 0">
                            <div class="top">
                              <el-image class="logo" fit="contain" :src="item.logo" :title="getLanguageData({zh: item.company_name, en: item.company_en_name})">
                              </el-image>
                              <div class="company-info">
                                <div class="name" @click="jump('/exhibitor', {id: item.id})" :title="getLanguageData({zh: item.company_name, en: item.company_en_name})">{{ getLanguageData({zh: item.company_name, en: item.company_en_name}) }}</div>
                                <div class="line2" v-if="item.booth">
                                  <div class="text1">{{$t('onlineExhibition.booth')}}：</div>
                                  <div class="text2 booth" :title="item.booth">{{item.booth}}</div>
                                </div>
                              </div>
                              <div class="line2" v-if="getLanguageData({zh: item.contact, en: item.contact_en})">
                                <div class="text1">{{$t('onlineExhibition.contact')}}：</div>
                                <div class="text2">{{ getLanguageData({zh: item.contact, en: item.contact_en}) }}</div>
                              </div>
                              <div class="line2" v-if="item.mobile">
                                <div class="text1">{{$t('onlineExhibition.mobile')}}：</div>
                                <div class="text2">{{item.mobile}}</div>
                              </div>
                              <div class="line2" v-if="getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})">
                                <div class="text1">{{$t('onlineExhibition.address')}}：</div>
                                <div class="text2" :title="getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})">{{ handleAddress(getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})) }}</div>
                              </div>
                            </div>
                          </div>
                          <!-- 无logo有产品样式 -->
                          <div class="item item3" v-else-if="!item.logo && item.count > 0">
                            <div class="top">
                              <div class="company-info">
                                <div class="name" @click="jump('/exhibitor', {id: item.id})" :title="getLanguageData({zh: item.company_name, en: item.company_en_name})">{{ getLanguageData({zh: item.company_name, en: item.company_en_name}) }}</div>
                                <div class="line2" v-if="item.booth">
                                  <div class="text1">{{$t('onlineExhibition.booth')}}：</div>
                                  <div class="text2 booth" :title="item.booth">{{item.booth}}</div>
                                </div>
                                <div class="top-contact">
                                  <div class="line2" v-if="getLanguageData({zh: item.contact, en: item.contact_en})">
                                    <div class="text1">{{$t('onlineExhibition.contact')}}：</div>
                                    <div class="text2 ellipsis" :title="getLanguageData({zh: item.contact, en: item.contact_en})">{{ getLanguageData({zh: item.contact, en: item.contact_en}) }}</div>
                                  </div>
                                  <div class="line2" v-if="item.mobile">
                                    <div class="text1">{{$t('onlineExhibition.mobile')}}：</div>
                                    <div class="text2">{{item.mobile}}</div>
                                  </div>
                                </div>
                                <div class="line2" v-if="getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})">
                                  <div class="text1">{{$t('onlineExhibition.address')}}：</div>
                                  <div class="text2 ellipsis" :title="getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})">{{ getLanguageData({zh: item.detailed_address, en: item.detailed_en_address}) }}</div>
                                </div>
                              </div>
                            </div>
                            <div class="bottom">
                              <div class="product-item" v-for="(toyItem, j) in item.toy" :key="j">
                                <el-image class="img pointer" fit="contain" :src="toyItem.main_picture" @click.native="jump('/goodsinfo', {id: toyItem.toy_id})" :title="getLanguageData({zh: toyItem.toy_name, en: toyItem.toy_name_en}) + '（' + toyItem.article_number + '）'">
                                </el-image>
                                <div class="name pointer" @click="jump('/goodsinfo', {id: toyItem.toy_id})" :title="getLanguageData({zh: toyItem.toy_name, en: toyItem.toy_name_en})">{{ getLanguageData({zh: toyItem.toy_name, en: toyItem.toy_name_en}) }}</div>
                                <div class="number" :title="toyItem.article_number">{{toyItem.article_number}}</div>
                              </div>
                              <div class="product-item-placeholder"></div>
                              <div class="product-item-placeholder"></div>
                              <div class="product-item-placeholder"></div>
                            </div>
                          </div>
                          <!-- 无logo无产品样式 -->
                          <div class="item item4" v-if="!item.logo && item.count == 0">
                            <div class="top">
                              <div class="company-info">
                                <div class="name" style="cursor:auto;" :title="getLanguageData({zh: item.company_name, en: item.company_en_name})">{{ getLanguageData({zh: item.company_name, en: item.company_en_name}) }}</div>
                                <div class="line2" v-if="item.booth">
                                  <div class="text1">{{$t('onlineExhibition.booth')}}：</div>
                                  <div class="text2 booth" :title="item.booth">{{item.booth}}</div>
                                </div>
                                <div class="line2" v-if="getLanguageData({zh: item.contact, en: item.contact_en})">
                                  <div class="text1">{{$t('onlineExhibition.contact')}}：</div>
                                  <div class="text2">{{ getLanguageData({zh: item.contact, en: item.contact_en}) }}</div>
                                </div>
                                <div class="line2" v-if="item.mobile">
                                  <div class="text1">{{$t('onlineExhibition.mobile')}}：</div>
                                  <div class="text2">{{item.mobile}}</div>
                                </div>
                                <div class="line2" v-if="getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})">
                                  <div class="text1">{{$t('onlineExhibition.address')}}：</div>
                                  <div class="text2" :title="getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})">{{ handleAddress(getLanguageData({zh: item.detailed_address, en: item.detailed_en_address})) }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div class="item-placeholder"></div>
                        <div class="item-placeholder"></div>
                        <div class="item-placeholder"></div>
                        <div  class="empty" v-if="list.length == 0">
                            <img :src="EmptyImg">
                            <div class="empty-tips">{{$t('common.emptyData')}}</div>
                        </div>
                    </div>
                    <div class="page" v-if="total">
                        <Page :page.sync="page" :pageSize="pageSize" :total="total" @current-change="handlePage"></Page>
                    </div>
                </div>
            </Block>
        </div>
        <Footer></Footer>
    </Main>
</template>

<script>
import { list, pavilion } from "@/api/online-exhibition.js";
import Main from '@/components/layout2/layout2-main.vue';
import Header from '@/components/layout2/layout2-header.vue';
import Footer from '@/components/layout2/layout2-footer.vue';
import Banner from '@/components/layout2/layout2-banner.vue';
import Block from '@/components/layout2/layout2-block.vue';
import Page from '@/components/layout2/layout2-page.vue';
import { getLanguageData, isEmpty } from "@/libs/tools.js";
import EmptyImg from '@/assets/img/empty.png'

export default {
    name: 'online-exhibition',
    components: {
        Main,
        Header,
        Footer,
        Banner,
        Block,
        Page,
    },
    data() {
        return {
            searchChecked: 1,
            searchSelect: [
                { label: this.$t('onlineExhibition.searchSelect1'), value: 1 },
                { label: this.$t('onlineExhibition.searchSelect2'), value: 2 },
            ],
            keyword: '',
            type: -1, //展馆类型
            typeList: [],
            list: [],
            loading: false,
            page: 1,
            pageSize: 10,
            total: 0,
            skipPage: '',
            EmptyImg: EmptyImg
        }
    },
    computed: {
        totalPage() {
            return Math.ceil(this.total / this.pageSize);
        }
    },
    mounted() {
        document.title = this.$t("common.title8");
        this.getPavilion(()=>{
          this.getList();
        });
    },
    methods: {
        getLanguageData,
        // 处理地址的英文逗号
        handleAddress(address){
          return address.replaceAll(',', ', ');
        },
        // 加载展馆列表
        getPavilion(callback){
          pavilion({}).then(res=>{
            if(res.code == 0){
              this.typeList = res.data;
              this.type = this.typeList[0]['id'];
              callback();
            }else{
              this.$message.error(res.msg);
            }
          })
        },
        // 展馆切换
        changeType(type){
          if(this.type == type) return false;
          this.type = type;
          this.search(); 
        },
        jump(path, query = {}) {
            let route = this.$router.resolve({ path: path, query: query });
            window.open(route.href, '_blank');
        },
        handlePage(page) {
            if(isEmpty(page)) page = 1;
            if(page > this.totalPage) page = this.totalPage;
            this.page = page;
            this.getList();
        },
        search(){
            this.page = 1;
            this.getList();
        },
        getList() {
            this.loading = true;
            let data = {
                page: this.page,
            };
            if(this.searchChecked == 1){
                data['name'] = this.keyword;
            }else if(this.searchChecked == 2){
                data['booth'] = this.keyword;
            }
            data['type'] = this.type;
            list(data).then(res=>{
                if(res.code == 0){
                    this.list = res.data.data;
                    this.total = res.data.total;
                    this.pageSize = res.data.per_page;
                }else{
                    this.$message.error(res.msg);
                }
            }).finally(()=>{
                this.loading = false;
            })
        }
    }
}
</script>
<style lang="less" scoped>
.background {
    background: #f6f6f6;
    width: 100%;
    display: flex;
    justify-content: center;
}
.pointer{
    cursor: pointer;
}
.online-exhibition {
    .title {
        border-bottom: 2px solid #3053A3;
        display: flex;
        justify-content: space-between;
        .left {
            flex: 1;
            display: flex;
            align-items: center;
            /deep/ .tabs-container{
              display: flex;
              align-items: center;
              padding-top: 45px;
              padding-bottom: 20px;
              .tab-item{
                flex-shrink: 0;
                font-size: 22px;
                font-weight: 400;
                color: #666666;
                padding-right: 66px;
                cursor: pointer;
                &.tab-item-active{
                  font-weight: bold;
                  color: #27458A;
                }
                &:first-child{
                  padding-left: 28px;
                }
                &:last-child{
                  padding-right: 28px;
                }
              }
            }
        }
        .right {
            display: flex;
            padding-top: 45px;
            padding-bottom: 20px;
            .search {
                width: 271px;
                height: 32px;
                border: 1px solid #dddddd;
                background: #FFFFFF;
                border-radius: 2px;
                display: flex;
                align-items: center;
                .line {
                    width: 1px;
                    height: 20px;
                    background: #eaeaea;
                }
                /deep/ .el-input__inner {
                    border: 0;
                    height: 32px;
                    font-size: 12px;
                    border-radius: 0;
                }
                .select {
                    width: 84px;
                    /deep/ .el-input__inner {
                        padding-left: 10px;
                        padding-right: 26px;
                    }
                    /deep/ input::-webkit-input-placeholder {
                        font-weight: 400;
                        color: #666666;
                    }
                    /deep/ .el-input__suffix {
                        .el-input__icon {
                            font-size: 8px;
                            width: 16px;
                            color: #cccccc;
                            line-height: 32px;
                        }
                    }
                    /deep/ .el-icon-arrow-up:before {
                        content: "\e78f";
                    }
                }
                .input {
                    flex: 1;
                    /deep/ .el-input__inner {
                        padding-left: 14px;
                        padding-right: 30px;
                    }
                    /deep/ input::-webkit-input-placeholder {
                        font-weight: 400;
                        color: #999999;
                    }
                    /deep/ .el-input__suffix {
                        .el-input__icon {
                            line-height: 32px;
                        }
                    }
                }
            }
            .search-button {
                margin-left: 10px;

                font-size: 12px;
                font-weight: 400;

                width: 80px;
                height: 32px;
                background: #3053A3;
                color: #FFFFFF;
                cursor: pointer;
                border-radius: 4px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item-placeholder {
            width: 394px;
        }
        .item {
            margin-top: 36px;
            width: 394px;
            min-height: 270px;
            background: #ffffff;
            border: 1px solid #eaeaea;
            opacity: 1;
            border-radius: 6px;
            padding: 16px;
            padding-bottom: 0;
            box-sizing: border-box;
            .top {
                height: 112px;
                border-bottom: 1px solid #dddddd;
                position: relative;
                .logo {
                    position: absolute;
                    top: -32px;
                    width: 98px;
                    height: 98px;
                    opacity: 1;
                    border-radius: 6px;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
                    background-color: #ffffff;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .company-info {
                    margin-left: 114px;
                    min-height: 68px;
                    .name {
                        cursor: pointer;
                        color: #333333;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: bold;
                        flex: 1;
                        margin-bottom: 4px;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                        overflow: hidden;
                    }
                    .name:hover {
                        color: #3053A3
                    }
                }
                .top-contact{
                  display: flex;
                  margin: 14px 0;
                  .line2{
                    &:first-child{
                      min-width: 150px;
                      margin-right: 5px;
                    }
                    &:last-child{
                      flex-shrink: 0;
                    }
                  }
                }
                .line2 {
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 400;
                  // word-break:break-all;
                  overflow: hidden;
                  display: flex;
                  &:first-child{
                    min-width: 114px;
                  }
                  .text1 {
                    flex-shrink: 0;
                    color: #666666;
                  }
                  .text2 {
                    flex: 1;
                    color: #333333;
                  }
                  .booth {
                    font-size: 12px;
                    font-weight: bold;
                    color: #CF000D;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
            }
            .bottom {
                padding: 16px 19px;
                padding-bottom: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .product-item-placeholder {
                    width: 88px;
                }
                .product-item {
                    width: 88px;
                    margin-bottom: 19px;
                    .img {
                        width: 88px;
                        height: 66px;
                        border: 1px solid #f2f2f2;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .name {
                        margin-top: 4px;
                        width: 100%;
                        font-size: 12px;
                        font-weight: 400;
                        color: #333333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .name:hover {
                        color: #3053A3;
                    }
                    .number {
                        margin-top: 2px;
                        width: 100%;
                        font-size: 12px;
                        font-weight: 400;
                        color: #999999;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .item2{
          .top{
            border-bottom: 0;
            height: auto;
            .company-info{
              .line2{
                margin-top: 0px;
              }
            }
            .line2{
              margin-top: 14px;
            }
          }
        }
        .item3{
          .top{
            .company-info{
              margin-left: 0px;
              .name{
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .top-contact{
                margin: 0;
              }
              .line2{
                margin-top: 10px;
              }
            }
          }
        }
        .item4{
          .top{
            height: auto;
            border-bottom: 0;
            .company-info{
              margin-left: 0px;
              .name{
                margin-bottom: 9px;
              }
              .line2{
                // font-size: 14px;
                margin-bottom: 14px;
              }
            }
          }
        }
        .item:hover{
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        }
    }
    .page {
        margin-top: 30px;
        margin-bottom: 40px;
    }
}
.empty{
    width: 240px;
    margin: 100px auto;
    font-size: 14px;
    color: #666666;
    text-align: center;
}
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
