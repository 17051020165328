import { render, staticRenderFns } from "./layout2-page.vue?vue&type=template&id=549a2f86&scoped=true&"
import script from "./layout2-page.vue?vue&type=script&lang=js&"
export * from "./layout2-page.vue?vue&type=script&lang=js&"
import style0 from "./layout2-page.vue?vue&type=style&index=0&id=549a2f86&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549a2f86",
  null
  
)

export default component.exports