<template>
    <div class="c-page">
        <div class="page-left">
            <button class="btn-prev" :disabled="page==1" @click="handlePage(page-1)"><i class="el-icon-arrow-left"></i>{{$t('page.prevPage')}}</button>
        </div>
        <el-pagination background @current-change="handlePage" :current-page.sync="c_page" :page-size="pageSize" layout="pager, slot" :total="total">
        </el-pagination>
        <div class="page-total">
            <button class="btn-next" :disabled="page>=totalPage" @click="handlePage(page+1)">{{$t('page.nextPage')}}<i class="el-icon-arrow-right"></i></button>
            <div class="red">{{page}}</div>/{{totalPage}}
            <div class="skip-left">{{$t('page.skipLeft')}}</div>
            <el-input class="skip-input" size="small" v-model="skipPage"></el-input>
            <div class="skip-right">{{$t('page.skipRight')}}</div>
            <div class="button" @click="handlePage(skipPage, true)">{{$t('page.skipButton')}}</div>
        </div>
    </div>
</template>

<script>
import { isEmpty } from "@/libs/tools.js";
export default {
    name: 'page',
    components: {

    },
    props: {
        //当前页码
        page: {
            type: Number,
            default: 1,
        },
        //每页大小
        pageSize: {
            type: Number,
            default: 10,
        },
        //总条数
        total: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            //跳转的页码
            skipPage: '',
        }
    },
    computed: {
        totalPage() {
            return Math.ceil(this.total / this.pageSize);
        },
        c_page:{
            set(val){
                this.$emit('update:page', val);
            },
            get(){
                return this.page;
            }
        }
    },
    mounted() {
        
    },
    methods: {
        handlePage(page, skip=false) {
            if(skip && isEmpty(page)) return false;   //默认空不管他
            //非数字设置成第一页
            page = Number(page);
            if(isNaN(page)) page = 1;

            //小于1或大于最后一页，设置成相应的值
            if (page < 1) page = 1;
            if (page > this.totalPage) page = this.totalPage;

            if(skip) this.skipPage = page;

            this.c_page = page;
            //切换页码抛出事件
            this.$emit('current-change', page);
        },
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.c-page {
    display: flex;
    justify-content: center;
    /deep/ .el-pagination {
        display: flex;
        padding: 2px 2px;
    }
    .btn-prev,
    .btn-next {
        cursor: pointer;
        min-width: 80px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        margin: 0 1px;

        span {
            line-height: 32px;
        }
        &:disabled {
            background: #f7f7f7;
            color: #cccccc;
        }
        &:not(:disabled):hover {
            color: #3053A3;
        }
    }
    .btn-prev {
        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
    .btn-next {
        i {
            margin-left: 5px;
            font-size: 14px;
        }
    }
    /deep/ .el-pager {
        .number {
            min-width: 32px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #eaeaea;
            border-radius: 5px;
            margin: 0 1px;
            font-size: 14px;
            line-height: 32px;
            color: #3d3d3d;
            font-weight: normal;
        }
        li:not(.disabled):hover {
            color: #3053A3;
        }
        li:not(.disabled).active {
            color: #ffffff;
            background-color: #3053A3;
        }
    }
    .page-left {
        margin: 2px 0;
    }
    .page-total {
        display: flex;
        font-size: 14px;
        margin-left: 12px;
        height: 32px;
        align-items: center;
        font-weight: normal;
        margin: 2px 0;
        .red {
            color: #3053A3;
            margin-left: 12px;
        }
        .skip-left {
            margin-left: 12px;
            margin-right: 10px;
        }
        .skip-right {
            margin-left: 10px;
        }
        .skip-input {
            width: 40px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #eaeaea;
            border-radius: 5px;
            /deep/ .el-input__inner {
                padding: 0 5px;
                border: 0;
            }
        }
        .button {
            width: 54px;
            height: 32px;
            cursor: pointer;

            margin-left: 6px;
            background: #ffffff;
            border: 1px solid #eaeaea;
            opacity: 1;
            border-radius: 5px;

            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                color: #3053A3;
            }
        }
    }
}
</style>
